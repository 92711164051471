import { FC, Fragment, useCallback } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { AppRoutes } from '../routes';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { UserAvatar } from './base/user-avatar';
import { useAuth } from '../hooks/state/auth.hook';
import { useAlertModal } from '../hooks/alert-modal.hook';
import { IAuthedAdmin } from '@avabusiness/shared/dist/auth/authed-admin.model';

const navigation = [
   { name: 'Dashboard', href: AppRoutes.dashboard },
   { name: 'Tenants', href: AppRoutes.tenants },
   { name: 'Support', href: AppRoutes.SupportTickets },
   { name: 'API', href: AppRoutes.ProductApiKeys },
   { name: 'Mail', href: AppRoutes.InternalMailSenderLogs },
   { name: 'Administratörer', href: AppRoutes.admins },
   { name: 'Aktivitet', href: AppRoutes.activity },
];

export interface HeaderProps {
   current?: IAuthedAdmin;
}

export const Header: FC<HeaderProps> = ({ current }) => {
   const { logout } = useAuth();
   const navigate = useNavigate();

   const { alert } = useAlertModal();

   const onLogoutClick = useCallback(() => {
      alert({
         text: 'Är du säker på att du vill logga ut?',
         state: 'warning',
         onConfirm: () => logout().then(() => navigate(AppRoutes.login)),
      });
   }, [alert, logout, navigate]);

   const userNavigation = [{ name: 'Logga ut', onClick: onLogoutClick }];

   return (
      <Disclosure as="nav" className="flex-shrink-0 bg-indigo-600">
         {({ open }) => (
            <>
               <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                  <div className="relative flex items-center justify-between h-16">
                     {/* Logo section */}
                     <Link to={AppRoutes.dashboard}>
                        <div className="flex items-center px-2 lg:px-0 xl:w-64">
                           <h1 className="text-xl text-white font-semibold">
                              Soermlandsfinans admin
                           </h1>
                        </div>
                     </Link>

                     {/* Links section */}
                     <div className="hidden lg:block lg:w-80">
                        <div className="flex items-center justify-end">
                           <div className="flex">
                              {navigation.map((item) => (
                                 <NavLink
                                    key={item.name}
                                    to={item.href}
                                    className={({ isActive }) =>
                                       classNames(
                                          'px-3 py-2 rounded-md text-sm font-medium text-indigo-200 hover:text-white',
                                          isActive ? 'bg-indigo-700' : '',
                                       )
                                    }
                                 >
                                    {item.name}
                                 </NavLink>
                              ))}
                           </div>
                           {/* Profile dropdown */}
                           <Menu
                              as="div"
                              className="ml-4 relative flex-shrink-0"
                           >
                              <div>
                                 <Menu.Button className="bg-indigo-700 flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white">
                                    <span className="sr-only">
                                       Open user menu
                                    </span>
                                    {current && (
                                       <UserAvatar
                                          user={{
                                             firstName: current.firstname,
                                             lastName: current.lastname,
                                          }}
                                       />
                                    )}
                                 </Menu.Button>
                              </div>
                              <Transition
                                 as={Fragment}
                                 enter="transition ease-out duration-100"
                                 enterFrom="transform opacity-0 scale-95"
                                 enterTo="transform opacity-100 scale-100"
                                 leave="transition ease-in duration-75"
                                 leaveFrom="transform opacity-100 scale-100"
                                 leaveTo="transform opacity-0 scale-95"
                              >
                                 <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {userNavigation.map((item) => (
                                       <Menu.Item key={item.name}>
                                          {({ active }) => (
                                             <a
                                                onClick={() => item.onClick?.()}
                                                className={classNames(
                                                   'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                                                   active ? 'text-white' : '',
                                                )}
                                             >
                                                {item.name}
                                             </a>
                                          )}
                                       </Menu.Item>
                                    ))}
                                 </Menu.Items>
                              </Transition>
                           </Menu>
                        </div>
                     </div>
                  </div>
               </div>

               <Disclosure.Panel className="lg:hidden">
                  <div className="px-2 pt-2 pb-3 space-y-1">
                     {navigation.map((item) => (
                        <Disclosure.Button
                           key={item.name}
                           as="a"
                           href={item.href}
                           className={classNames(
                              true
                                 ? 'text-white bg-indigo-800'
                                 : 'text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600',
                              'block px-3 py-2 rounded-md text-base font-medium',
                           )}
                           aria-current={true ? 'page' : undefined}
                        >
                           {item.name}
                        </Disclosure.Button>
                     ))}
                  </div>
                  <div className="pt-4 pb-3 border-t border-indigo-800">
                     <div className="px-2 space-y-1">
                        {userNavigation.map((item) => (
                           <Disclosure.Button
                              key={item.name}
                              as="a"
                              onClick={() => item.onClick?.()}
                              className="block px-3 py-2 rounded-md text-base font-medium text-indigo-200 hover:text-indigo-100 hover:bg-indigo-600"
                           >
                              {item.name}
                           </Disclosure.Button>
                        ))}
                     </div>
                  </div>
               </Disclosure.Panel>
            </>
         )}
      </Disclosure>
   );
};
