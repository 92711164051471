import {
   IAdmin,
   ICreateAdmin,
} from '@avabusiness/shared/dist/models/admin.model';
import create from 'zustand';
import { AdminApi } from '../../api/admin.api';

type AdminState = {
   admins: IAdmin[] | undefined;
   getAdmins(): Promise<IAdmin[]>;
   getById(id: string): Promise<IAdmin>;
   removeAdmin(id: string): Promise<void>;
   createAdmin(admin: ICreateAdmin): Promise<string>;
};

export const useAdmins = create<AdminState>((set, get) => {
   return {
      admins: undefined,
      async getAdmins() {
         const admins = await AdminApi.GetAll();
         set({ admins });
         return admins;
      },
      getById(id: string) {
         return AdminApi.GetById(id);
      },
      async removeAdmin(id: string) {
         const req = await AdminApi.Remove(id);
         get().getAdmins();
         return req;
      },
      async createAdmin(admin: ICreateAdmin) {
         const req = await AdminApi.Create(admin);
         if (req) get().getAdmins();
         return req;
      },
   };
});
