import { IAuthedAdmin } from '@avabusiness/shared/dist/auth/authed-admin.model';
import Cookies from 'js-cookie';

export const getAccessToken = () => Cookies.get(AUTH_TOKENS.ACCESS);
export const getRefreshToken = () => Cookies.get(AUTH_TOKENS.REFRESH);

export const isAuthenticated = (cb?: () => void) => {
   cb && cb();
   const token = !!getAccessToken();
   if (!token) {
      localStorage.removeItem('currentAdmin');
   }

   return token;
};

export const setTokens = (currentUser?: IAuthedAdmin) => {
   const inOneDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

   const expires = inOneDay;

   Cookies.set(AUTH_TOKENS.ACCESS, 'true', {
      expires: expires,
   });

   const currentUserShallow = currentUser
      ? makeAuthedUser(currentUser)
      : undefined;

   currentUserShallow &&
      localStorage.setItem('currentAdmin', JSON.stringify(currentUserShallow));

   return currentUser;
};

const makeAuthedUser = (selectedUser: IAuthedAdmin): IAuthedAdmin => {
   return selectedUser;
};

export const AUTH_TOKENS = {
   ACCESS: 'bizniz_access_token',
   REFRESH: 'refresh_token',
   BANKID_SESSION_ID: 'bankid_sessionid',
   SHALLOWUSER: 'shallow_user_token',
};

export const logout = async () => {
   Cookies.remove(AUTH_TOKENS.ACCESS);
   Cookies.remove(AUTH_TOKENS.REFRESH);
   Cookies.remove(AUTH_TOKENS.SHALLOWUSER);

   localStorage.removeItem('currentAdmin');
};
