import classNames from 'classnames';
import { FC, useMemo } from 'react';

const firstLetter = (str: string) => {
   if (!str) return null;
   return str[0].toUpperCase();
};

interface UserAvatarProps {
   user: {
      firstName: string;
      lastName: string;
   };
   color?: string;
   size?: 'sm' | 'md' | 'lg';
}

export const UserAvatar: FC<UserAvatarProps> = ({ user, size, color }) => {
   const twoLetterName = useMemo(() => {
      const letterFname = firstLetter(user.firstName);
      const letterLname = firstLetter(user.lastName);
      return letterLname && letterFname ? `${letterFname}${letterLname}` : null;
   }, [user.firstName, user.lastName]);

   const wh = useMemo(() => {
      if (!size) return 'h-10 w-10';
      if (size === 'sm') return 'h-6 w-6';
      if (size === 'md') return 'h-10 w-10';
      if (size === 'lg') return 'h-14 w-14';
   }, [size]);

   return (
      <div
         className={classNames(
            wh,
            color ? color : 'bg-indigo-400',
            'inline-block rounded-full flex justify-center items-center',
         )}
      >
         <p
            className={classNames(
               'font-semibold text-white',
               size === 'sm' && 'text-xs',
            )}
         >
            {twoLetterName}
         </p>
      </div>
   );
};
