import {
   ICreateUser,
   ICreateUserRole,
   IEditableUserInfo,
   IUser,
   IUserRole,
   UserType,
   UserTypeRolesUnion,
} from '@avabusiness/shared/dist/models/user.model';
import create from 'zustand';
import { UserApi } from '../../api/user.api';

type UsersState = {
   editUser: (
      userId: string,
      newData: Partial<IEditableUserInfo>,
   ) => Promise<boolean>;
   fetchUsers: (tenantId: string) => Promise<IUser[]>;
   fetchAdmins: (tenantId: string) => Promise<IUser[]>;
   fetchById: (id: string) => Promise<IUser | null>;
   createUser: (user: ICreateUser) => Promise<string | null>;
   getUsersByUserTypeAndRole: (
      tenantId: string,
      userType: UserType,
      role: UserTypeRolesUnion,
   ) => Promise<IUser[] | null>;
   updateRoles(
      userId: string,
      roles: ICreateUserRole[],
   ): Promise<IUserRole[] | null>;
   deleteUser: (userId: string) => Promise<boolean>;
};
export const useUsers = create<UsersState>((set, get) => {
   return {
      async getUsersByUserTypeAndRole(
         tenantId: string,
         userType: UserType,
         role: UserTypeRolesUnion,
      ) {
         const users = await UserApi.GetUsersByUserTypeAndRole(
            tenantId,
            userType,
            role,
         );
         return users;
      },
      async updateRoles(
         userId: string,
         roles: ICreateUserRole[],
      ): Promise<IUserRole[] | null> {
         const update = await UserApi.UpdateRoles(userId, roles);
         return update;
      },
      editUser: async (userId: string, newData: Partial<IEditableUserInfo>) => {
         return new Promise(async (resolve, reject) => {
            try {
               const updated = await UserApi.UpdateInformation(userId, newData);
               resolve(!!updated);
            } catch (error) {
               reject(error);
            }
         });
      },
      async fetchById(id: string) {
         return await UserApi.GetUserById(id);
      },
      fetchUsers: async function (tenantId: string) {
         const req = await UserApi.GetUsers(tenantId);
         return req;
      },
      async fetchAdmins(tenantId: string) {
         const req = await UserApi.GetAdmins(tenantId);
         return req;
      },
      async createUser(user: ICreateUser) {
         const req = await UserApi.CreateUser(user);
         return req;
      },
      deleteUser: async (userId: string): Promise<boolean> => {
         const req = await UserApi.DeleteUser(userId);
         return !!req;
      },
   };
});
