import { ILicense } from './license.model';

export enum UserType {
   Admin = 1,
   User = 2,
}

export interface IAuthTokens {
   access: string;
   refresh: string;
   expires?: number;
}

export interface ICreateUser {
   tenantId: string;
   personalNumber?: string;
   email: string;
   withCustomerId?: string;
   type: UserType;
}

export interface IEditableUserInfo
   extends Omit<ICreateUser, 'withCustomerId' | 'type'> {
   firstName: string;
   lastName: string;
   phone: string;
}

export interface IUser
   extends Omit<ICreateUser, 'withCustomerId'>,
      IEditableUserInfo {
   id: string;
   tokens?: IAuthTokens;
   registered: boolean;
   roles: IUserRole[];
   invitationDate: Date;
   isCompanyOwner: boolean;
   tenantLicense?: IUserTenantLicense;
}

export interface IUserTenantLicense
   extends Pick<ILicense, 'id' | 'capabilities'> {}

export enum AdminRoles {
   SuperAdmin = 1,
   ReadOnly = 2,
   ChatRepresentative = 3,
   InvoiceSales = 4,
   LoanSales = 5,
   Financial = 6,
}

export enum UserRoles {
   ReadOnly = 1,
}

export type UserTypeRolesUnion = UserRoles | AdminRoles;

/**
 * @version 2.0
 */
export interface ICreateUserRole {
   userType: UserType;
   userId: string;
   role: AdminRoles | UserRoles;
}
export interface IUserRole extends ICreateUserRole {
   id: string;
   createdDate: Date;
}
