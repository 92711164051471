import {
   ICreateTenantWithOptions,
   ITenant,
} from '@avabusiness/shared/dist/models/tenant.model';
import Config from '../config';
import axiosApiInstance, { catchError } from './axios.interceptor';

export class TenantApi {
   static GetById(id: string) {
      return axiosApiInstance
         .get<ITenant>(`${Config.apiUri}/tenant/${id}`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static GetAll() {
      return axiosApiInstance
         .get<ITenant[]>(`${Config.apiUri}/tenant`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static GetWithoutLicenses() {
      return axiosApiInstance
         .get<ITenant[]>(`${Config.apiUri}/tenant/no-license`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static Remove(id: string) {
      return axiosApiInstance
         .delete<void>(`${Config.apiUri}/tenant/${id}`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static Create(tenant: ICreateTenantWithOptions) {
      return axiosApiInstance
         .post<string>(`${Config.apiUri}/tenant`, {
            data: tenant,
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }
}
