import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SimpleSpinner } from './simple-spinner';

export interface SimpleButtonProps {
   onClick?: (
      done?: () => void,
   ) => Promise<any> | undefined | null | boolean | void;
   title?: string;
   leftIcon?: any;
   primary?: boolean;
   disabled?: boolean;
   color?: 'gray' | 'white' | 'red' | 'green' | undefined;
   transparent?: boolean;
   children?: JSX.Element | string;
   isLoading?: boolean;
   fullWidth?: boolean;
}

export const SimpleButton: FC<SimpleButtonProps> = (props) => {
   const {
      onClick,
      title,
      leftIcon,
      primary,
      disabled,
      color,
      children,
      transparent,
      isLoading,
      fullWidth,
   } = props;

   const [isLoadingState, setIsLoadingState] = useState(isLoading);

   const onClickCb = useCallback(() => {
      const click = onClick?.(() => setIsLoadingState(false));

      const isPromise = !!(
         click &&
         typeof click === 'object' &&
         typeof click.then === 'function'
      );
      isPromise && setIsLoadingState(true);

      isPromise &&
         click
            .then(() => setIsLoadingState(false))
            .catch(() => setIsLoadingState(false));
   }, [onClick]);

   const className = useMemo(
      () =>
         primary
            ? 'inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm'
            : 'inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm',
      [primary],
   );

   const isDisabledMemo = useMemo(
      () => isLoadingState || disabled,
      [disabled, isLoadingState],
   );

   const colorMemo = useMemo(() => {
      if (transparent) return 'transparent hover:bg-gray-200';

      switch (color) {
         case 'white':
            return 'bg-white';
         case 'gray':
            return 'bg-gray-100 hover:bg-gray-200';
         case 'red':
            return 'bg-red-400 hover:bg-red-500 text-gray-50';
         case 'green':
            return 'bg-green-500';
         default:
            return 'bg-gray-100 hover:bg-gray-200';
      }
   }, [color, transparent]);

   useEffect(() => {
      setIsLoadingState(isLoading);
   }, [isLoading]);

   return (
      <button
         disabled={isDisabledMemo}
         onClick={onClickCb}
         type="button"
         className={classNames(
            className,
            colorMemo,
            fullWidth ? 'w-full' : undefined,
         )}
      >
         {!isLoadingState ? (
            <>
               {leftIcon && (
                  <props.leftIcon
                     className="-ml-1 mr-2 h-5 w-5"
                     aria-hidden="true"
                  />
               )}
               {title || children}
            </>
         ) : (
            <SimpleSpinner size="xs" />
         )}
      </button>
   );
};
