import {
   ICreateEvent,
   IEvent,
} from '@avabusiness/shared/dist/models/event.model';
import Config from '../config';
import axiosApiInstance, { catchError } from './axios.interceptor';

export class EventApi {
   static GetAll() {
      return axiosApiInstance
         .get<IEvent[]>(`${Config.apiUri}/event`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static Create(event: ICreateEvent) {
      return axiosApiInstance
         .post<string>(`${Config.apiUri}/event`, {
            data: event,
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }
}
