import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
const DashboardPage = lazy(() => import('./pages/dashboard.page'));
const TenantsPage = lazy(() => import('./pages/tenants.page'));

const TenantPage = lazy(() => import('./pages/tenant.page'));
const CreateTenantPage = lazy(() => import('./pages/create-tenant.page'));
const AdminsPage = lazy(() => import('./pages/admins.page'));
const LoginPage = lazy(() => import('./pages/login.page'));
const ActivityPage = lazy(() => import('./pages/activity.page'));
const LicensePage = lazy(() => import('./pages/license.page'));
const LicensesPage = lazy(() => import('./pages/licenses.page'));
const CreateLicensePage = lazy(() => import('./pages/create-license.page'));
const AWSPage = lazy(() => import('./pages/aws.page'));
const S3BucketPage = lazy(() => import('./pages/s3-bucket.page'));
const IAMProfilePage = lazy(() => import('./pages/iam-profile.page'));
const BankIdProfilesPage = lazy(() => import('./pages/bankid-profiles.page'));
const BankIdProfilePage = lazy(() => import('./pages/bankid-profile.page'));
const CreditCheckServicesPage = lazy(
   () => import('./pages/creditcheck-services.page'),
);
const CreditCheckServicePage = lazy(
   () => import('./pages/creditcheck-service.page'),
);
const MailerServicesPage = lazy(() => import('./pages/mailer-services.page'));
const MailerServicePage = lazy(() => import('./pages/mailer-service.page'));
const TenantDatabasesPage = lazy(() => import('./pages/databases.page'));
const InternalDbPage = lazy(() => import('./pages/internal-database.page'));
const ExternalDbPage = lazy(() => import('./pages/external-database.page'));

const InvoiceAiProfilesPage = lazy(
   () => import('./pages/invoice-ai-profiles.page'),
);
const InvoiceAiApiTypeProfilesPage = lazy(
   () => import('./pages/invoice-ai-api-type-profiles.page'),
);
const InvoiceAiProfilePage = lazy(
   () => import('./pages/invoice-ai-profile.page'),
);
const InvoiceAiApiTypeProfilePage = lazy(
   () => import('./pages/invoice-ai-api-type-profile.page'),
);
const ProductApiKeyPage = lazy(() => import('./pages/product-api-key.page'));
const ProductApiKeysPage = lazy(() => import('./pages/product-api-keys.page'));
const ProductApiResourcesPage = lazy(
   () => import('./pages/product-api-resources.page'),
);
const InternalMailSenderLogsPage = lazy(
   () => import('./pages/internal-mail-sender-logs.page'),
);
const SupportTicketsPage = lazy(() => import('./pages/support-tickets.page'));
const SupportTicketPage = lazy(() => import('./pages/support-ticket.page'));

export enum AppRoutes {
   login = '/',
   dashboard = '/',
   createTenant = '/create-tenant',
   tenants = '/tenants',
   tenant = '/tenant',
   activity = '/activity',
   admins = '/admins',
   admin = '/admin',
   licenses = '/licenses',
   license = '/license',
   createLicense = '/create-license',
   aws = '/aws',
   s3Bucket = '/s3-bucket',
   iamProfile = '/iam-profile',
   bankIdProfiles = '/bankid-profiles',
   creditCheckServices = '/creditcheck-services',
   bankIdProfile = '/bankid-profile',
   creditCheckService = '/creditcheck-service',
   mailerServices = '/mailer-services',
   mailerService = '/mailer-service',
   tenantDatabases = '/tenant-databases',
   externalDatabase = '/external-database',
   internalDatabase = '/internal-database',
   invoiceAiProfiles = '/invoice-ai-profiles',
   invoiceAiProfile = '/invoice-ai-profile',
   invoiceAiApiProfiles = '/invoice-ai-api-profiles',
   invoiceAiApiProfile = '/invoice-ai-api-profile',
   ProductApiKeys = '/product-api-keys',
   ProductApiKey = '/product-api-key',
   ProductApiResources = '/product-api-resources',
   InternalMailSenderLogs = '/internal-mail-sender-logs',
   SupportTickets = '/support-tickets',
   SupportTicket = '/support-ticket',
}

export const AuthedRoutes = () => {
   return (
      <Suspense fallback={<div></div>}>
         <Routes>
            <Route path={AppRoutes.dashboard} element={<DashboardPage />} />
            <Route path={AppRoutes.tenants} element={<TenantsPage />} />
            <Route path={AppRoutes.tenant} element={<TenantPage />} />
            <Route path={AppRoutes.admins} element={<AdminsPage />} />
            <Route path={AppRoutes.activity} element={<ActivityPage />} />
            <Route
               path={AppRoutes.createTenant}
               element={<CreateTenantPage />}
            />
            <Route path={AppRoutes.license} element={<LicensePage />} />
            <Route path={AppRoutes.licenses} element={<LicensesPage />} />
            <Route
               path={AppRoutes.createLicense}
               element={<CreateLicensePage />}
            />
            <Route path={AppRoutes.aws} element={<AWSPage />} />
            <Route path={AppRoutes.s3Bucket} element={<S3BucketPage />} />
            <Route path={AppRoutes.iamProfile} element={<IAMProfilePage />} />
            <Route
               path={AppRoutes.bankIdProfiles}
               element={<BankIdProfilesPage />}
            />
            <Route
               path={AppRoutes.creditCheckServices}
               element={<CreditCheckServicesPage />}
            />
            <Route
               path={AppRoutes.creditCheckService}
               element={<CreditCheckServicePage />}
            />
            <Route
               path={AppRoutes.bankIdProfile}
               element={<BankIdProfilePage />}
            />
            <Route
               path={AppRoutes.mailerServices}
               element={<MailerServicesPage />}
            />
            <Route
               path={AppRoutes.mailerService}
               element={<MailerServicePage />}
            />
            <Route
               path={AppRoutes.tenantDatabases}
               element={<TenantDatabasesPage />}
            />
            <Route
               path={AppRoutes.externalDatabase}
               element={<ExternalDbPage />}
            />
            <Route
               path={AppRoutes.internalDatabase}
               element={<InternalDbPage />}
            />
            <Route
               path={AppRoutes.invoiceAiProfile}
               element={<InvoiceAiProfilePage />}
            />
            <Route
               path={AppRoutes.invoiceAiApiProfile}
               element={<InvoiceAiApiTypeProfilePage />}
            />
            <Route
               path={AppRoutes.invoiceAiProfiles}
               element={<InvoiceAiProfilesPage />}
            />
            <Route
               path={AppRoutes.invoiceAiApiProfiles}
               element={<InvoiceAiApiTypeProfilesPage />}
            />
            <Route
               path={AppRoutes.ProductApiKey}
               element={<ProductApiKeyPage />}
            />
            <Route
               path={AppRoutes.ProductApiKeys}
               element={<ProductApiKeysPage />}
            />
            <Route
               path={AppRoutes.ProductApiResources}
               element={<ProductApiResourcesPage />}
            />
            <Route
               path={AppRoutes.InternalMailSenderLogs}
               element={<InternalMailSenderLogsPage />}
            />
            <Route
               path={AppRoutes.SupportTickets}
               element={<SupportTicketsPage />}
            />
            <Route
               path={AppRoutes.SupportTicket}
               element={<SupportTicketPage />}
            />
         </Routes>
      </Suspense>
   );
};

export const UnauthedRoutes = () => {
   return (
      <Suspense fallback={<div></div>}>
         <Routes>
            <Route path={AppRoutes.login} element={<LoginPage />} />
            <Route path="*" element={<Navigate to={AppRoutes.login} />} />
         </Routes>
      </Suspense>
   );
};
