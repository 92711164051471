import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Header } from './components/header';
import { useAuth } from './hooks/state/auth.hook';
import { isAuthenticated } from './services/auth.service';
import { useEffect, useMemo } from 'react';
import { useAdmins } from './hooks/state/admin.hook';
import { useTenants } from './hooks/state/tenant.hook';
import { useEvents } from './hooks/state/event.hook';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { AuthedRoutes, UnauthedRoutes } from './routes';
import SimpleAlertModal from './components/base/simple-alert.modal';
import { InviteTenantUserModal } from './components/users/invite-or-update-user.modal';
import publisher, { Resource } from './services/pub-sub';

export const PreloadResources = () => {
   const { getAdmins } = useAdmins();
   const { getTenants } = useTenants();
   const { getRecentEvents } = useEvents();

   useEffect(() => {
      publisher.subscribe(Resource.HttpError, (data: { message: string }) => {
         const title = data.message ?? 'Någonting gick fel..';
         toast.error(title);
      });
   }, []);

   useEffect(() => {
      getAdmins();
      getTenants();
      getRecentEvents();
   }, [getAdmins, getRecentEvents, getTenants]);

   return <></>;
};

export const InitModals = () => {
   return (
      <>
         <SimpleAlertModal />
         <InviteTenantUserModal />
      </>
   );
};

function App() {
   const { current } = useAuth();

   const isLoggedIn = useMemo(() => current || isAuthenticated(), [current]);

   return (
      <div className="App bg-white ">
         <ToastContainer
            autoClose={1200}
            position={toast.POSITION.BOTTOM_RIGHT}
         />
         <Router>
            <div className="h-full">
               {/* Background color split screen for large screens */}

               <div className="relative min-h-full flex flex-col">
                  {isLoggedIn ? (
                     <>
                        <InitModals />

                        <PreloadResources />
                        <Header current={current} />
                        <AuthedRoutes />
                     </>
                  ) : (
                     <UnauthedRoutes />
                  )}
               </div>
            </div>
         </Router>
      </div>
   );
}

export default App;
