import {
   ICreateTenantWithOptions,
   ITenant,
} from '@avabusiness/shared/dist/models/tenant.model';
import create from 'zustand';
import { TenantApi } from '../../api/tenant.api';

type TenantState = {
   tenants: ITenant[] | undefined;
   getTenants: () => Promise<ITenant[]>;
   getById(id: string): Promise<ITenant>;
   getWhereNoLicenses: () => Promise<ITenant[]>;
   createTenant(tenant: ICreateTenantWithOptions): Promise<string>;
   removeTenant(id: string): Promise<void>;
};

export const useTenants = create<TenantState>((set, get) => {
   return {
      tenants: undefined,
      async getTenants() {
         const tenants = await (TenantApi.GetAll() || undefined);
         set({ tenants });
         return tenants;
      },
      async getWhereNoLicenses() {
         const tenants = await (TenantApi.GetWithoutLicenses() || undefined);
         return tenants;
      },
      getById(id: string) {
         return TenantApi.GetById(id);
      },
      async createTenant(tenant: ICreateTenantWithOptions) {
         const add = await TenantApi.Create(tenant);
         if (add) get().getTenants();
         return add;
      },
      async removeTenant(id: string) {
         const remove = await TenantApi.Remove(id);
         get().getTenants();
         return remove;
      },
   };
});
