import { AdminRoles } from '../models/user.model';

export const TranslateAdminRoles = (role: AdminRoles): string => {
   switch (role) {
      case AdminRoles.ChatRepresentative:
         return 'Chattrepresentatör';
      case AdminRoles.Financial:
         return 'Ekonomi';
      case AdminRoles.InvoiceSales:
         return 'Fakturasälj';
      case AdminRoles.LoanSales:
         return 'Lånsälj';
      case AdminRoles.ReadOnly:
         return 'Läsrättigheter';
      case AdminRoles.SuperAdmin:
         return 'Ägare';
      default:
         return `${role}`;
   }
};
