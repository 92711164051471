import axios from 'axios';
import publisher, { Resource } from '../services/pub-sub';
const API_KEY = '1G5#_gl446ghja!_#6alPFyla#589sdxl';

export const catchError = (ex: any) => {
   console.warn(ex);

   const responseMessage = ex?.response?.data
      ? typeof ex.response?.data === 'string'
         ? ex.response?.data
         : ex.response?.data?.message
      : undefined;
   if (responseMessage)
      publisher.publish(Resource.HttpError, {
         message: responseMessage,
      });
   throw responseMessage ?? 'Något gick fel';
};

const axiosApiInstance = axios.create({
   validateStatus: function (status) {
      const valid = [200, 201, 204];
      return valid.includes(status);
   },
});

axiosApiInstance.interceptors.request.use(
   async (config: any) => {
      const user = localStorage.getItem('currentAdmin') ?? undefined;
      config.headers = {
         Authorization: user,
         'business-api-key': API_KEY,
      };
      return config;
   },
   (error: any) => {
      Promise.reject(error);
   },
);

export default axiosApiInstance;
