import {
   ICreateEvent,
   IEvent,
} from '@avabusiness/shared/dist/models/event.model';
import create from 'zustand';
import { EventApi } from '../../api/event.api';

type EventState = {
   events: IEvent[] | undefined;
   getRecentEvents(): Promise<IEvent[]>;
   createEvent(event: ICreateEvent): Promise<string>;
};

export const useEvents = create<EventState>((set, get) => {
   return {
      events: undefined,
      async getRecentEvents() {
         const events = await EventApi.GetAll();
         set({ events });
         return events;
      },
      async createEvent(event: ICreateEvent) {
         const req = await EventApi.Create(event);
         get().getRecentEvents();
         return req;
      },
   };
});
