import { IAuthedAdmin } from '@avabusiness/shared/dist/auth/authed-admin.model';
import create from 'zustand';
import { AuthApi } from '../../api/auth.api';
import {
   isAuthenticated,
   logout,
   setTokens,
} from '../../services/auth.service';

type AuthState = {
   current: IAuthedAdmin | undefined;
   auth: (username: string, password: string) => Promise<IAuthedAdmin>;
   logout: () => Promise<void>;
};

export const useAuth = create<AuthState>((set, get) => {
   const shallowUser = localStorage.getItem('currentAdmin');
   return {
      current:
         isAuthenticated() && shallowUser && shallowUser !== ''
            ? JSON.parse(shallowUser)
            : undefined,
      async auth(username: string, password: string) {
         const req = await AuthApi.AuthAdmin(username, password);
         if (req) {
            set({ current: req });
            setTokens(req);
         }

         return req;
      },
      async logout() {
         await logout();
         set({ current: undefined });
         return;
      },
   };
});
