import { IUser, UserType } from '@avabusiness/shared/dist/models/user.model';
import create from 'zustand';

export type InviteUserStateType = {
   type: UserType;
   tenantId: string;
   onCreate?: () => void;
};

export type UpdateUserStateType = {
   user: IUser;
};

export type InviteOrUpdateUserStateType =
   | InviteUserStateType
   | UpdateUserStateType;

type InviteOrUpdateUserModalState = {
   modals: InviteOrUpdateUserStateType[];
   create: (modal: InviteOrUpdateUserStateType) => void;
   destroy: (modalId: InviteOrUpdateUserStateType) => void;
};

export const useInviteOrUpdateUserModal = create<InviteOrUpdateUserModalState>(
   (set) => ({
      modals: [],
      create: (modal) => set((state) => ({ modals: [...state.modals, modal] })),
      destroy: (modal) =>
         set((state) => ({
            modals: state.modals.filter((n) => n !== modal),
         })),
   }),
);
