import { IAuthedAdmin } from '@avabusiness/shared/dist/auth/authed-admin.model';
import Config from '../config';
import axiosApiInstance, { catchError } from './axios.interceptor';

export class AuthApi {
   static AuthAdmin(username: string, password: string) {
      return axiosApiInstance
         .post<IAuthedAdmin>(`${Config.apiUri}/auth`, {
            data: {
               username,
               password,
            },
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }
}
