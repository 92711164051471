/* eslint-disable react-hooks/exhaustive-deps */
import { EnumToArray } from '@avabusiness/shared/dist/util/array.util';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useUsers } from '../../hooks/state/users.hook';
import { SimpleInput } from '../base/simple-input';
import { SimpleModal } from '../base/simple-modal';
import { TranslateAdminRoles } from '@avabusiness/shared/dist/translators/user.translator';
import { SimpleCheckboxes } from '../base/simple-checkbox';
import {
   AdminRoles,
   ICreateUserRole,
   UserRoles,
   UserType,
} from '@avabusiness/shared/dist/models/user.model';
import { useInviteOrUpdateUserModal } from '../../hooks/invite-or-update-user-modal.hook';

const removeAtIndex = (arr: any[], index: number) => {
   const copy = [...arr];
   copy.splice(index, 1);
   return copy;
};

const addOrRemoveRole = (
   currentRoles: ICreateUserRole[],
   newRole: ICreateUserRole,
   getValue = (newRole: ICreateUserRole) => newRole,
) => {
   const index = currentRoles.findIndex(
      (i) =>
         getValue(i).role === getValue(newRole).role &&
         getValue(i).userType === getValue(newRole).userType,
   );

   if (index === -1) return [...currentRoles, newRole];
   return removeAtIndex(currentRoles, index);
};

const availableAdminRoles = EnumToArray(AdminRoles).map((role) => ({
   label: TranslateAdminRoles(role.value),
   value: role.value,
}));
const availableUserRoles = EnumToArray(UserRoles);

export const InviteTenantUserModal = () => {
   const { modals, destroy } = useInviteOrUpdateUserModal();
   const modalToShow = useMemo(() => modals[modals.length - 1], [modals]);

   const user =
      modalToShow && 'user' in modalToShow ? modalToShow.user : undefined;
   const isCreateUserType =
      modalToShow && 'type' in modalToShow ? modalToShow.type : undefined;
   const tenantId =
      modalToShow && 'tenantId' in modalToShow
         ? modalToShow.tenantId
         : undefined;

   const onCreateCallback =
      modalToShow && 'onCreate' in modalToShow
         ? modalToShow.onCreate
         : undefined;

   const availableRolesMemo = useMemo(() => {
      const userType = user?.type || isCreateUserType;
      if (!userType) return undefined;
      return userType === UserType.Admin
         ? availableAdminRoles
         : availableUserRoles;
   }, [isCreateUserType, user?.type]);

   const { createUser, editUser, updateRoles } = useUsers();

   const [currentRoles, setCurrentRoles] = useState<ICreateUserRole[]>(
      user?.roles || [],
   );

   const [adminemail, setadminemail] = useState(user?.lastName || '');
   const [adminssn, setadminssn] = useState('');
   const [adminphone, setadminphone] = useState(user?.phone || '');

   useEffect(() => {
      setadminemail(user?.email || '');
      setadminphone(user?.phone || '');
      setCurrentRoles(user?.roles ?? []);
   }, [user]);

   const onClose = useCallback(() => {
      destroy(modalToShow);
   }, [destroy, modalToShow]);

   const resetAdminCreationFields = useCallback(() => {
      setadminphone('');
      setadminssn('');
      setadminemail('');
   }, []);

   const invitationBtnDisabled = useMemo(
      () =>
         !user
            ? adminemail === '' || adminssn === '' || adminphone === ''
            : false,
      [user, adminemail, adminphone, adminssn],
   );

   const createAdminClick = useCallback(() => {
      const body = {
         email: adminemail,
         phone: adminphone,
         personalNumber: adminssn,
      };

      const userRequest =
         !user?.id && isCreateUserType && tenantId
            ? createUser({
                 ...body,
                 type: isCreateUserType,
                 tenantId: tenantId,
              })
            : user?.id && editUser(user.id, body);

      return userRequest
         ? userRequest.then((res) => {
              if (res) {
                 onCreateCallback?.();
                 onClose();

                 const userId = res && typeof res === 'string' ? res : user?.id;

                 userId &&
                    updateRoles(userId, currentRoles).then((res) =>
                       console.warn(res),
                    );
                 !user
                    ? toast.success('Inbjudan skickad!')
                    : toast.success('Användare uppdaterad!');
              } else {
                 !user
                    ? toast.error('Kunde inte skapa användare..')
                    : toast.error('Kunde inte redigera användare..');
              }
              resetAdminCreationFields();
           })
         : Promise.reject();
   }, [
      adminemail,
      adminphone,
      adminssn,
      user,
      isCreateUserType,
      createUser,
      tenantId,
      editUser,
      resetAdminCreationFields,
      onClose,
      updateRoles,
      currentRoles,
   ]);

   const modalTitleMemo = useMemo(
      () => `${user ? 'Redigera' : 'Bjud in'} ${'administratör'}`,
      [user],
   );

   const modalButtonsMemo = useMemo(
      () => [
         {
            label: 'Stäng',
            closeOnClick: true,
         },
         {
            label: ` ${user ? 'Uppdatera' : 'Skicka inbjudan'}`,
            onClick: createAdminClick,
            disabled: invitationBtnDisabled,
         },
      ],
      [user, createAdminClick, invitationBtnDisabled],
   );

   const checkboxesMemo = useMemo(
      () =>
         availableRolesMemo
            ? availableRolesMemo.map((role) => ({
                 checked: !!currentRoles?.find(
                    (currentRole) => currentRole.role === role.value,
                 ),
                 item: {
                    label: role.label,
                 },
                 onChange: () =>
                    setCurrentRoles((cur) =>
                       addOrRemoveRole(cur, {
                          userId: '',
                          role: role.value,
                          userType: UserType.Admin,
                       }),
                    ),
              }))
            : undefined,

      [availableRolesMemo, currentRoles],
   );

   const modalContentMemo = useMemo(
      () => (
         <div>
            <div className="mb-4 grid-grid-cols-1 gap-40">
               <SimpleInput
                  value={adminemail}
                  placeholder="E-post"
                  label="E-post"
                  type="email"
                  onChange={setadminemail}
               />
            </div>
            <div className="grid grid-cols-2 gap-4">
               <SimpleInput
                  value={adminphone}
                  placeholder="Telefon"
                  label="Telefon"
                  onChange={setadminphone}
               />

               {!user && (
                  <SimpleInput
                     value={adminssn}
                     label="Personnummer"
                     placeholder="Personnummer"
                     onChange={setadminssn}
                  />
               )}
            </div>

            {checkboxesMemo && <SimpleCheckboxes items={checkboxesMemo} />}
         </div>
      ),
      [user, adminemail, adminphone, adminssn, checkboxesMemo],
   );

   return (
      <SimpleModal
         open={!!modalToShow}
         onClose={onClose}
         title={modalTitleMemo}
         content={modalContentMemo}
         buttons={modalButtonsMemo}
      />
   );
};
