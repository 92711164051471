export function EnumToArray(enumme: any): { label: string; value: number }[] {
   const arr = Object.keys(enumme).map((key) => enumme[key]);

   if (arr.length % 2 == 0) {
      const half = Math.ceil(arr.length / 2);

      const labels = arr.splice(0, half);
      const values = arr.splice(-half);
      return labels.map((label, i) => ({
         value: values[i] as number,
         label: label as string,
      }));
   }
   return [];
}

export const arrayHasItems = (arr: any) => {
   return arr && !!arr.length && arr.length > 0;
};

export async function filterAsync<T>(
   arr: T[],
   callback: (item: T) => Promise<boolean>,
) {
   return (
      await Promise.all(
         arr.map(async (item) => ((await callback(item)) ? item : null)),
      )
   ).filter((i) => i !== null);
}
