import { toast } from 'react-toastify';

export enum Resource {
   Tenant = 1,
   Admin = 2,
   HttpError = 3,
}

export class Publisher {
   private subscribers: {
      func: Function;
      resource: Resource;
   }[] = [];

   private unsubscribe(subscriptionId: number) {
      return () => {
         const subscriberFns = [...this.subscribers];
         subscriberFns.forEach(({ func }, index: number) => {
            if (this.createSubscriptionId(func) === subscriptionId) {
               subscriberFns.splice(index, 1);
               this.subscribers = [...subscriberFns];
            }
         });
      };
   }

   public unsubscribleAll() {
      this.subscribers = [];
   }

   public subscribe(resource: Resource, subscriberFn: Function): () => void {
      this.subscribers = [
         ...this.subscribers,
         { func: subscriberFn, resource: resource },
      ];
      const subscriptionId = this.createSubscriptionId(subscriberFn);
      return this.unsubscribe(subscriptionId);
   }

   public publish(_resource: Resource, data?: any): void {
      this.subscribers.forEach(({ func, resource }) => {
         if (_resource === resource) {
            if (resource !== Resource.HttpError)
               toast.promise(
                  new Promise((resolve) => setTimeout(resolve, 1000)),
                  {
                     pending: 'Laddar viktig data...',
                     success: 'Klart!',
                     error: 'Misslyckades med att hämta ny data.',
                  },
               );
            func(data);
         }
      });
   }

   private createSubscriptionId(subscriberFn: Function): number {
      const encodeString =
         Math.floor(Math.random() * 200000) + 1 + subscriberFn.toString();
      return encodeString.split('').reduce((accumulator, char) => {
         return char.charCodeAt(0) + ((accumulator << 5) - accumulator);
      }, 0);
   }
}

const publisher = new Publisher();

export default publisher;
