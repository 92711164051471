import { FC } from 'react';

interface SimpleCheckboxesProps {
   title?: string;
   items: SimpleCheckboxProps[];
}

interface Checkbox {
   label: string;
   description?: string;
}

export const SimpleCheckboxes: FC<SimpleCheckboxesProps> = ({
   title,
   items,
}) => {
   return (
      <fieldset className="space-y-5">
         <legend className="sr-only">{title}</legend>
         <legend className="text-lg font-medium text-gray-900">{title}</legend>
         {items.map((item, idx) => (
            <SimpleCheckbox key={idx} {...item} />
         ))}
      </fieldset>
   );
};

interface SimpleCheckboxProps {
   item: Checkbox;
   checked?: boolean;
   onChange: (checked: boolean) => void;
}

export const SimpleCheckbox: FC<SimpleCheckboxProps> = ({
   item,
   checked,
   onChange,
}) => {
   return (
      <div className="relative flex items-start">
         <div className="flex items-center h-5">
            <input
               onChange={({ target }) => onChange(target.checked)}
               checked={checked}
               id="comments"
               aria-describedby="comments-description"
               name="comments"
               type="checkbox"
               className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
         </div>
         <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
               {item.label}
            </label>
            {item.description && (
               <p id="comments-description" className="text-gray-500">
                  {item.description}
               </p>
            )}
         </div>
      </div>
   );
};
