import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useAlertModal } from '../../hooks/alert-modal.hook';
import { SimpleInput } from './simple-input';

export default function SimpleAlertModal() {
   const { modals, destroy } = useAlertModal();

   const [confirmDeleteText, setConfirmDeleteText] = useState('');

   const modalToShow = useMemo(
      () => modals.filter((modal) => modal.type === 'alert')[modals.length - 1],
      [modals],
   );

   const btnDisabled = useMemo(
      () => confirmDeleteText !== 'delete' && modalToShow?.confirmDelete,
      [confirmDeleteText, modalToShow?.confirmDelete],
   );

   const onClose = useCallback(() => {
      modalToShow?.onDecline?.();
      setConfirmDeleteText('');
      destroy(modalToShow);
   }, [destroy, modalToShow]);

   const onConfirm = useCallback(() => {
      if (btnDisabled) return;
      modalToShow.onConfirm();
      setConfirmDeleteText('');
      destroy(modalToShow);
   }, [btnDisabled, destroy, modalToShow]);

   const cancelButtonRef = useRef(null);

   return (
      <Transition.Root show={!!modalToShow} as={Fragment}>
         <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={onClose}
         >
            <Transition.Child
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed z-10 inset-0 overflow-y-auto">
               <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                  <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                        <div className="sm:flex sm:items-start">
                           <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                              <ExclamationCircleIcon
                                 className="h-6 w-6 text-red-600"
                                 aria-hidden="true"
                              />
                           </div>
                           <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              {modalToShow?.text && (
                                 <Dialog.Title
                                    as="h3"
                                    className="text-lg leading-6 font-medium text-gray-900"
                                 >
                                    {modalToShow.text}
                                 </Dialog.Title>
                              )}
                              <div className="mt-2">
                                 {modalToShow?.description && (
                                    <p className="text-sm text-gray-500">
                                       {modalToShow.description}
                                    </p>
                                 )}
                              </div>
                           </div>
                        </div>

                        {modalToShow?.confirmDelete && (
                           <div className="mt-4">
                              <SimpleInput
                                 value={confirmDeleteText}
                                 placeholder="delete"
                                 onChange={setConfirmDeleteText}
                                 label="Verifera med att skriva 'delete'"
                              />
                           </div>
                        )}

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                           <button
                              type="button"
                              disabled={btnDisabled}
                              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={onConfirm}
                           >
                              Fortsätt
                           </button>
                           <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                              onClick={onClose}
                              ref={cancelButtonRef}
                           >
                              Avbryt
                           </button>
                        </div>
                     </Dialog.Panel>
                  </Transition.Child>
               </div>
            </div>
         </Dialog>
      </Transition.Root>
   );
}
