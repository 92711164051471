import create from 'zustand';

export type AlertModalTypes = 'alert' | 'single-action' | 'double-action';

export type AlertModalStates = 'success' | 'error' | 'warning';

export interface AlertModalStateType {
   state?: AlertModalStates | 'warning';
   text: string;
   confirmDelete?: boolean;
   description?: string;
   onConfirm: () => void;
   onDecline?: () => void;
}

interface IAlertModal extends AlertModalStateType {
   type: AlertModalTypes;
}

type ConfirmModalState = {
   modals: IAlertModal[];
   singleAction: (modal: AlertModalStateType) => void;
   doubleAction: (modal: AlertModalStateType) => void;
   alert: (modal: AlertModalStateType) => void;
   destroy: (modal: AlertModalStateType) => void;
};

export const useAlertModal = create<ConfirmModalState>((set) => ({
   modals: [],
   doubleAction: (modal) =>
      set((state) => ({
         modals: [...state.modals, { ...modal, type: 'double-action' }],
      })),
   alert: (modal) =>
      set((state) => ({
         modals: [...state.modals, { ...modal, type: 'alert' }],
      })),
   singleAction: (modal) =>
      set((state) => ({
         modals: [...state.modals, { ...modal, type: 'single-action' }],
      })),
   destroy: (modal) =>
      set((state) => ({
         modals: state.modals.filter((n) => n !== modal),
      })),
}));
