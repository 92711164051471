import {
   ICreateUser,
   ICreateUserRole,
   IUserRole,
   UserType,
   UserTypeRolesUnion,
   IUser,
   IEditableUserInfo,
} from '@avabusiness/shared/dist/models/user.model';
import Config from '../config';
import axiosApiInstance, { catchError } from './axios.interceptor';

export class UserApi {
   static GetUsers = (tenantId: string) => {
      return axiosApiInstance
         .get<IUser[]>(`${Config.apiUri}/user/${tenantId}/users`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         });
   };

   static GetAdmins = (tenantId: string) => {
      return axiosApiInstance
         .get<IUser[]>(`${Config.apiUri}/user/${tenantId}/admins`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);
   };

   static GetUserById = (id: string) => {
      return axiosApiInstance
         .get<IUser>(`${Config.apiUri}/user/${id}`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);
   };

   static DeleteUser = (userId: string) =>
      axiosApiInstance
         .delete<boolean>(`${Config.apiUri}/user/${userId}`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);

   static CreateUser = (user: ICreateUser) => {
      return axiosApiInstance
         .post<string>(`${Config.apiUri}/user`, {
            data: user,
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);
   };

   static UpdateRoles = (userId: string, roles: ICreateUserRole[]) =>
      axiosApiInstance
         .put<IUserRole[]>(`${Config.apiUri}/user/${userId}/roles`, {
            data: roles,
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);

   static GetUsersByUserTypeAndRole = (
      tenantId: string,
      userType: UserType,
      role: UserTypeRolesUnion,
   ) =>
      axiosApiInstance
         .get<IUser[]>(
            `${Config.apiUri}/user/${tenantId}/type/${userType}/role/${role}`,
            {
               headers: {
                  'Content-Type': 'application/json',
               },
            },
         )
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);

   static UpdateInformation = (
      userId: string,
      userdata: Partial<IEditableUserInfo>,
   ) =>
      axiosApiInstance
         .put<boolean>(`${Config.apiUri}/user/${userId}`, {
            data: userdata,
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => {
            return resp.data;
         })
         .catch(catchError);
}
