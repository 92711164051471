import {
   IAdmin,
   ICreateAdmin,
} from '@avabusiness/shared/dist/models/admin.model';
import Config from '../config';
import axiosApiInstance, { catchError } from './axios.interceptor';

export class AdminApi {
   static Remove(id: string) {
      return axiosApiInstance
         .delete<void>(`${Config.apiUri}/admin/${id}`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static Create(admin: ICreateAdmin) {
      return axiosApiInstance
         .post<string>(`${Config.apiUri}/admin`, {
            data: admin,
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static GetById(id: string) {
      return axiosApiInstance
         .get<IAdmin>(`${Config.apiUri}/admin/${id}`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }

   static GetAll() {
      return axiosApiInstance
         .get<IAdmin[]>(`${Config.apiUri}/admin`, {
            headers: {
               'Content-Type': 'application/json',
            },
         })
         .then((resp) => resp.data)
         .catch(catchError);
   }
}
